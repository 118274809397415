import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { Breadcrumbs, FlexColumn, Heading, PinnableSummaryCards, TopNavigation } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Styled from 'styled-components';
import Util from '../../forecast-app/shared/util/util';
import ProjectIndicator from '../../forecast-app/shared/components/project-indicator/ProjectIndicator';
import ActionBar from '../../forecast-app/navigation/header/ActionBar';
import { useHistory } from 'react-router-dom';
import { projectUrl } from '../../directApi';
import { showModal, MODAL_TYPE } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import { PhaseCard } from './PhaseDeliverablesCard';
import BulkUpdatePhaseBaselineRolesMutation from '../../mutations/bulk_update_phase_baseline_roles_mutation';
import BulkUpdatePhaseBaselineExpensesMutation from '../../mutations/bulk_update_phase_baseline_expenses_mutation';
import BulkUpdatePhaseDeliverablesMutation from '../../mutations/bulk_update_phase_deliverables_mutation';
import { getInitialBaselineItems, getInitialPhaseDeliverables } from './Util';
const PageWrapper = Styled.div `
  padding: 32px;
`;
const BreadcrumbsWrapper = Styled.div `
  padding-bottom: 24px;	
`;
export const getTotals = (baselineItems) => {
    const totals = baselineItems.reduce((result, item) => {
        if ('phaseBaselineRoleId' in item) {
            result.baselineMinutes += item.hours * 60;
            result.baselineCost += item.cost;
            result.baselineRevenue += item.revenue;
            result.baselineTimeAndExpenses += item.valueOfService;
        }
        else if ('phaseBaselineExpenseId' in item) {
            result.baselineCost += item.cost;
            result.baselineRevenue += item.revenue;
            result.baselineTimeAndExpenses += Math.max(item.revenue, item.cost);
        }
        return result;
    }, {
        baselineCost: 0,
        baselineMargin: 0,
        baselineMinutes: 0,
        baselineProfit: 0,
        baselineRevenue: 0,
        baselineTimeAndExpenses: 0,
    });
    totals.baselineProfit = totals.baselineRevenue - totals.baselineCost;
    totals.baselineMargin = totals.baselineRevenue
        ? Util.roundFloatToTwoDecimals(totals.baselineProfit / totals.baselineRevenue)
        : 0;
    return totals;
};
const BaselineEditPage = ({ viewer }) => {
    var _a, _b;
    const intl = useIntl();
    const { formatMessage } = intl;
    const history = useHistory();
    const scrollRef = useRef(null);
    const { company, project } = viewer;
    useEffect(() => {
        document.title = 'Baseline - Edit';
    }, []);
    const currency = ((project === null || project === void 0 ? void 0 : project.rateCard) ? project === null || project === void 0 ? void 0 : project.rateCard.currency : company === null || company === void 0 ? void 0 : company.currency) || '';
    const currencySymbol = Util.GetCurrencySymbol(currency);
    const [rolesUpdateSucceded, setRolesUpdateSucceded] = useState(false);
    const [expensesUpdateSucceded, setExpensesUpdateSucceded] = useState(false);
    if (rolesUpdateSucceded && expensesUpdateSucceded) {
        sessionStorage.setItem('baselineProjectUpdate', 'true');
        history.push(project ? `${projectUrl(project.companyProjectId, project.customProjectId)}/baseline` : ``);
    }
    const initialBaselineItems = useMemo(() => getInitialBaselineItems(project), [project]);
    const [projectBaselineItems, setProjectBaselineItemsBaselineItems] = useState(initialBaselineItems);
    const initialPhaseDeliverables = useMemo(() => getInitialPhaseDeliverables(project), [project]);
    const [projectPhaseDeliverables, setProjectPhaseDeliverables] = useState(initialPhaseDeliverables);
    const [projectBaselineTotals, setProjectBaselineTotals] = useState((project === null || project === void 0 ? void 0 : project.financialNumbers) || null);
    const { baselineCost, baselineMargin, baselineMinutes, baselineProfit, baselineRevenue, baselineTimeAndExpenses } = projectBaselineTotals || {};
    const baselineRoles = projectBaselineItems === null || projectBaselineItems === void 0 ? void 0 : projectBaselineItems.filter(item => item && 'phaseBaselineRoleId' in item && item.roleId);
    const baselineExpenses = projectBaselineItems === null || projectBaselineItems === void 0 ? void 0 : projectBaselineItems.filter(item => item && 'phaseBaselineExpenseId' in item && item.categoryId);
    const rolesTotals = useMemo(() => getTotals(baselineRoles), [projectBaselineItems]);
    const expensesTotals = useMemo(() => getTotals(baselineExpenses), [projectBaselineItems]);
    const groupedProjectTotals = { rolesTotals, expensesTotals };
    useEffect(() => {
        const projectTotals = getTotals(projectBaselineItems);
        setProjectBaselineTotals(projectTotals);
    }, [projectBaselineItems]);
    const onClickSave = () => {
        const onPhaseDeliverablesUpdateSuccess = () => {
            const phaseBaselineRoles = baselineRoles.map(item => {
                return {
                    id: item.phaseBaselineRoleId,
                    projectId: item.projectId,
                    roleId: item.roleId,
                    phaseId: item.phaseId,
                    deliverableId: item.deliverableId,
                    baselineMinutes: item.hours * 60,
                };
            });
            Util.CommitMutation(BulkUpdatePhaseBaselineRolesMutation, { phaseBaselineRoles, projectId: project === null || project === void 0 ? void 0 : project.id }, () => setRolesUpdateSucceded(true));
            const phaseBaselineExpenses = baselineExpenses.map(item => {
                return {
                    id: item.phaseBaselineExpenseId,
                    projectId: item.projectId,
                    expenseCategoryId: item.categoryId,
                    phaseId: item.phaseId,
                    deliverableId: item.deliverableId,
                    expenseCost: item.cost,
                    expenseMarkup: item.markup,
                    expenseRevenue: item.revenue,
                    expenseName: item.name,
                };
            });
            Util.CommitMutation(BulkUpdatePhaseBaselineExpensesMutation, { phaseBaselineExpenses, projectId: project === null || project === void 0 ? void 0 : project.id }, () => setExpensesUpdateSucceded(true));
        };
        const phaseDeliverablesMutation = {
            projectId: project === null || project === void 0 ? void 0 : project.id,
            phaseDeliverables: projectPhaseDeliverables.map(phaseDeliverable => {
                var _a;
                return ({
                    id: phaseDeliverable.id,
                    phaseId: phaseDeliverable.phaseId,
                    deliverableId: (_a = phaseDeliverable.deliverable) === null || _a === void 0 ? void 0 : _a.id,
                    projectId: project === null || project === void 0 ? void 0 : project.id,
                });
            }),
        };
        Util.CommitMutation(BulkUpdatePhaseDeliverablesMutation, phaseDeliverablesMutation, onPhaseDeliverablesUpdateSuccess);
    };
    const onClickCancel = () => {
        showModal({
            type: MODAL_TYPE.EXIT_ACTION_BAR_MODAL,
            callback: () => {
                history.push(project ? `${projectUrl(project.companyProjectId, project.customProjectId)}/baseline` : ``);
            },
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TopNavigation, null,
            React.createElement(ActionBar, { onClickSave: onClickSave, onClickCancel: onClickCancel })),
        React.createElement(CustomScrollDiv, { setScrollbarsRef: node => {
                scrollRef.current = node;
            } },
            React.createElement(PageWrapper, null,
                React.createElement(BreadcrumbsWrapper, null,
                    React.createElement(Breadcrumbs, null,
                        React.createElement(Breadcrumbs.Item, null,
                            React.createElement(FormattedMessage, { id: "common.all_projects" })),
                        project && (React.createElement(Breadcrumbs.Item, null,
                            React.createElement("div", null,
                                project.name,
                                React.createElement(ProjectIndicator, { project: project })))),
                        React.createElement(Breadcrumbs.Item, null,
                            React.createElement(FormattedMessage, { id: "baseline.edit_baseline.title" })))),
                React.createElement(FlexColumn, { gap: 'l' },
                    React.createElement(Heading, { size: '3' },
                        React.createElement(FormattedMessage, { id: "baseline.edit_baseline.title" })),
                    React.createElement(PinnableSummaryCards, { topOffset: 57, summaryData: [
                            {
                                title: formatMessage({ id: 'common.total_hours' }),
                                value: Util.convertMinutesToFullHour(baselineMinutes || 0, intl),
                            },
                            {
                                title: formatMessage({ id: 'common.total_value_of_service' }),
                                value: Util.getFormattedNumberWithCurrency(currencySymbol, baselineTimeAndExpenses || 0, intl),
                            },
                            {
                                title: formatMessage({ id: 'common.total_revenue_capitalized' }),
                                value: Util.getFormattedNumberWithCurrency(currencySymbol, baselineRevenue || 0, intl),
                            },
                            {
                                title: formatMessage({ id: 'common.total_cost' }),
                                value: Util.getFormattedNumberWithCurrency(currencySymbol, baselineCost || 0, intl),
                            },
                            {
                                title: formatMessage({ id: 'common.total_profit' }),
                                value: Util.getFormattedNumberWithCurrency(currencySymbol, baselineProfit || 0, intl),
                            },
                            {
                                title: formatMessage({ id: 'common.total_margin' }),
                                value: Util.getFormattedPercentage(baselineMargin || 0, intl),
                            },
                        ] })),
                React.createElement(FlexColumn, { gap: "s" }, (_b = (_a = project === null || project === void 0 ? void 0 : project.phases) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(phaseEdge => {
                    if ((phaseEdge === null || phaseEdge === void 0 ? void 0 : phaseEdge.node) && company) {
                        const phase = phaseEdge.node;
                        const items = projectBaselineItems
                            .filter(item => item.phaseId === phase.id)
                            .map((item, index) => (Object.assign(Object.assign({}, item), { index })));
                        return (React.createElement(PhaseCard, { phase: phase, currency: currency, company: company, phaseBaselineItems: items, project: project, projectPhaseDeliverables: projectPhaseDeliverables, editMode: true, setProjectBaselineItems: setProjectBaselineItemsBaselineItems, setProjectPhaseDeliverables: setProjectPhaseDeliverables, groupedProjectTotals: groupedProjectTotals }));
                    }
                    return null;
                }).filter(Boolean))))));
};
export default createRefetchContainer(BaselineEditPage, {
    viewer: graphql `
			fragment BaselineEditPage_viewer on Viewer @argumentDefinitions(projectId: {type: "String"}) {
				id
				company {
					id
					name
					currency
					roles {
						edges {
							node {
								id
								name
							}
						}
					}
					expenseCategories {
						edges {
							node {
								id
								name
								disabled
							}
						}
					}
				}
				project(id: $projectId) {
					...ProjectIndicator_project
					useBaseline
					useDeliverables
					budgetType
					budget
					id
					status
					companyProjectId
					customProjectId
					projectColor
					name
					baselineEstimationMinutesPerDay
					projectStartYear
					projectStartMonth
					projectStartDay
					projectEndYear
					projectEndMonth
					projectEndDay
					baselineTargetMinutes
					baselineTarget
					baselineCost
					baselineProfit
					baselineAdjustPercentage
					baselineAdjustPrice
					baselineTargetPrice
					useFixedPriceForBaselineRevenue
					rateCard {
						id
						currency
						disabledRoles {
							id
						}
						rates {
							edges {
								node {
									rate
									defaultRate
									role {
										id
									}
								}
							}
						}
					}
					financialNumbers(convertToProjectCurrency: true) {
						baselineMinutes
						baselineRevenue
						baselineTimeAndExpenses
						baselineCost
						baselineProfit
						baselineMargin
					}
					deliverables(first: 1000000) @connection(key: "Project_deliverables", filters: []) {
						edges {
							node {
								id
								name
								description
								projectId
							}
						}
					}
					phases(first: 1000000) @connection(key: "Project_phases", filters: []) {
						edges {
							node {
								id
								name
								startYear
								startMonth
								startDay
								deadlineDay
								deadlineMonth
								deadlineYear
								projectId
								averageRatesByRole {
									roleId
									costRate
									rate
								}
								phaseDeliverables(first: 10000) @connection(key: "Phase_phaseDeliverables", filters: []) {
									edges {
										node {
											id
											phaseId
											deliverable {
												id
												name
											}
											financialNumbers(convertToProjectCurrency: true) {
												baselineMinutes
												baselineTimeAndExpenses
												baselineRevenue
												baselineCost
												baselineProfit
												baselineMargin
											}
										}
									}
								}
							}
						}
					}
					phaseBaselineExpenses(first: 1000000) @connection(key: "Project_phaseBaselineExpenses", filters: []) {
						edges {
							node {
								id
								expenseName
								expenseCategory {
									name
									id
								}
								expenseRevenue
								expenseCost
								expenseMarkup
								expenseProfit
								globalPhaseId
								deliverableId
							}
						}
					}
					phaseBaselineRoles(first: 1000000) @connection(key: "Project_phaseBaselineRoles", filters: []) {
						edges {
							node {
								id
								globalPhaseId
								deliverableId
								role {
									name
									id
								}
								financialNumbers(convertToProjectCurrency: true) {
									baselineMinutes
									baselineTimeAndExpenses
									baselineRevenue
									baselineCost
									baselineProfit
									baselineRatePerHour
									baselineCostPerHour
								}
							}
						}
					}
				}
			}
		`,
}, graphql `
		query BaselineEditPageRefetchQuery($projectId: String) {
			viewer {
				component(name: "edit_baseline")
				...BaselineEditPage_viewer @arguments(projectId: $projectId)
			}
		}
	`);
