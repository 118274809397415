/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
export type FILTER_SECTION = "FINANCIAL_PORTFOLIO_REPORT" | "INVOICING_INVOICES" | "INVOICING_OVERVIEW" | "LABELS" | "MY_LIST" | "MY_TASKS" | "MY_TIMESHEETS" | "MY_WORK" | "PROJECT" | "PROJECTS_OVERVIEW" | "PROJECT_TIMELINE" | "REPORTED_TIME_TABLE" | "SCHEDULING_CAPACITY_OVERVIEW" | "SCHEDULING_PEOPLE" | "SCHEDULING_PLACEHOLDERS" | "SCHEDULING_PROJECTS" | "SCOPING" | "SKILLS" | "SPRINT" | "SPRINT_BACKLOG" | "TASK_REPORT" | "TEAM_TIMESHEET" | "TIME_APPROVAL" | "UTILIZATION_REPORT" | "WORKFLOW" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type InvoicesPage_viewer$ref: FragmentReference;
declare export opaque type InvoicesPage_viewer$fragmentType: InvoicesPage_viewer$ref;
export type InvoicesPage_viewer = {|
  +id: string,
  +filters: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +section: ?FILTER_SECTION,
        +value: ?string,
      |}
    |}>
  |},
  +company: ?{|
    +currency: ?string,
    +newXeroEnabled: ?boolean,
    +quickbooksEnabled: ?boolean,
    +quickbooksRealmId: ?string,
    +economicEnabled: ?boolean,
    +xeroShortCode: ?string,
    +sageIntacctConnectionEnabled: ?boolean,
    +clients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +xeroContactId: ?string,
          +economicCustomerId: ?string,
          +quickbooksCustomerId: ?string,
          +sageIntacctCustomerId: ?string,
          +quickbooksRealmId: ?string,
        |}
      |}>
    |},
    +integrations: ?{|
      +userCanUnlinkInvoice: ?boolean
    |},
    +allPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
        |}
      |}>
    |},
    +labels: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
  |},
  +invoicesV2: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +companyInvoiceId: ?number,
        +name: ?string,
        +status: ?string,
        +externalStatus: ?string,
        +paymentStatus: ?string,
        +currency: ?string,
        +hasEntriesWithNoProject: ?boolean,
        +invoicedWithoutTax: ?number,
        +invoicedWithoutTaxBaseCurrency: ?number,
        +paidWithoutTax: ?number,
        +paidWithoutTaxBaseCurrency: ?number,
        +createdYear: ?number,
        +createdMonth: ?number,
        +createdDay: ?number,
        +dueYear: ?number,
        +dueMonth: ?number,
        +dueDay: ?number,
        +xeroId: ?string,
        +xeroTenantId: ?string,
        +quickbooksId: ?number,
        +quickbooksRealmId: ?string,
        +economicId: ?number,
        +sageIntacctId: ?string,
        +invoiceReference: ?string,
        +billTo: ?{|
          +id: string,
          +name: string,
          +billFrom: ?{|
            +id: string,
            +name: string,
            +integration: string,
          |},
        |},
        +client: ?{|
          +id: string,
          +name: ?string,
        |},
        +projects: ?$ReadOnlyArray<?{|
          +id: string,
          +companyProjectId: ?number,
          +customProjectId: ?string,
          +name: ?string,
          +projectColor: ?string,
          +projectPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +isContactPerson: ?boolean,
                +person: ?{|
                  +id: string
                |},
              |}
            |}>
          |},
          +projectLabels: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +label: ?{|
                  +id: string
                |},
              |}
            |}>
          |},
          +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
        |}>,
      |}
    |}>
  |},
  +$refType: InvoicesPage_viewer$ref,
|};
export type InvoicesPage_viewer$data = InvoicesPage_viewer;
export type InvoicesPage_viewer$key = {
  +$data?: InvoicesPage_viewer$data,
  +$fragmentRefs: InvoicesPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quickbooksRealmId",
  "storageKey": null
},
v7 = {
  "kind": "Literal",
  "name": "excludeClientUsers",
  "value": true
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v9 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v10 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDate",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDate",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "filters"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "invoicesV2"
        ]
      }
    ]
  },
  "name": "InvoicesPage_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "filters",
      "args": null,
      "concreteType": "FilterTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_filters_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FilterTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Filter",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "section",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newXeroEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quickbooksEnabled",
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "economicEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "xeroShortCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sageIntacctConnectionEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientTypeConnection",
          "kind": "LinkedField",
          "name": "clients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "xeroContactId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "economicCustomerId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "quickbooksCustomerId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sageIntacctCustomerId",
                      "storageKey": null
                    },
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyIntegrationsType",
          "kind": "LinkedField",
          "name": "integrations",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userCanUnlinkInvoice",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v7/*: any*/),
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000000
            },
            {
              "kind": "Literal",
              "name": "onlyActive",
              "value": true
            }
          ],
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "allPersons",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "allPersons(excludeClientUsers:true,first:1000000,onlyActive:true)"
        },
        {
          "alias": null,
          "args": (v8/*: any*/),
          "concreteType": "LabelTypeConnection",
          "kind": "LinkedField",
          "name": "labels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LabelTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Label",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v9/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "labels(first:10000)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "invoicesV2",
      "args": [
        {
          "kind": "Variable",
          "name": "endDate",
          "variableName": "endDate"
        },
        {
          "kind": "Variable",
          "name": "startDate",
          "variableName": "startDate"
        }
      ],
      "concreteType": "InvoiceTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_invoicesV2_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InvoiceTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InvoiceType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyInvoiceId",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "externalStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentStatus",
                  "storageKey": null
                },
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasEntriesWithNoProject",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoicedWithoutTax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoicedWithoutTaxBaseCurrency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paidWithoutTax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paidWithoutTaxBaseCurrency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dueYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dueMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dueDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "xeroId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "xeroTenantId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quickbooksId",
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "economicId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sageIntacctId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoiceReference",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BillTo",
                  "kind": "LinkedField",
                  "name": "billTo",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BillFrom",
                      "kind": "LinkedField",
                      "name": "billFrom",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "integration",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "client",
                  "plural": false,
                  "selections": (v9/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "projects",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyProjectId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "customProjectId",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectColor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        (v7/*: any*/),
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 100000
                        }
                      ],
                      "concreteType": "ProjectPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "projectPersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isContactPerson",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": (v10/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "projectPersons(excludeClientUsers:true,first:100000)"
                    },
                    {
                      "alias": null,
                      "args": (v8/*: any*/),
                      "concreteType": "ProjectLabelTypeConnection",
                      "kind": "LinkedField",
                      "name": "projectLabels",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectLabelTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectLabel",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Label",
                                  "kind": "LinkedField",
                                  "name": "label",
                                  "plural": false,
                                  "selections": (v10/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "projectLabels(first:10000)"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "DeprecatedProjectIndicatorJS_project"
                    }
                  ],
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e80a7b38bc130c90f291d0e8114c8ca3';

module.exports = node;
