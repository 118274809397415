import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { Table } from '@forecast-it/design-system';
export const ExpensesEstimatesTable = forwardRef((props, ref) => {
    var _a, _b;
    const { editMode, onDeleteRow, company, onCategorySelect, recalculateRow, tableData, setTableData, eyeOptionMap } = props;
    const { formatMessage } = useIntl();
    const getExpenseCategoryName = (row) => {
        var _a, _b;
        const category = (_b = (_a = company === null || company === void 0 ? void 0 : company.expenseCategories) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.find(edge => { var _a; return ((_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.id) === row.categoryId; });
        if (category && category.node && category.node.name) {
            return category.node.name;
        }
        return formatMessage({ id: 'deliverable.select_expense_category' });
    };
    const currency = company.currency || '';
    return (React.createElement(Table, { width: '100%', tableRef: ref, setTableData: setTableData, data: tableData },
        editMode ? (React.createElement(Table.TextInputColumn, { accessorKey: 'name', header: formatMessage({ id: 'deliverable.columns.name' }), onInputChange: recalculateRow, width: "14%" })) : (React.createElement(Table.TextColumn, { accessorKey: 'name', header: formatMessage({ id: 'deliverable.columns.name' }), ellipsis: true })),
        editMode ? (React.createElement(Table.DropdownColumn, { header: formatMessage({ id: 'deliverable.columns.category' }), label: getExpenseCategoryName, width: "14%" }, (_b = (((_a = company === null || company === void 0 ? void 0 : company.expenseCategories) === null || _a === void 0 ? void 0 : _a.edges) || [])) === null || _b === void 0 ? void 0 : _b.map(category => {
            var _a;
            return (React.createElement(Table.DropdownColumn.ListItem, { onClick: row => {
                    var _a;
                    ((_a = category === null || category === void 0 ? void 0 : category.node) === null || _a === void 0 ? void 0 : _a.id) && onCategorySelect(category === null || category === void 0 ? void 0 : category.node.id, row);
                } }, (_a = category === null || category === void 0 ? void 0 : category.node) === null || _a === void 0 ? void 0 : _a.name));
        }))) : (React.createElement(Table.TextColumn, { accessorKey: 'categoryName', header: formatMessage({ id: 'deliverable.columns.category' }) })),
        editMode ? (React.createElement(Table.NumberInputColumn, { accessorKey: 'revenue', header: formatMessage({ id: 'deliverable.columns.revenue' }), onInputChange: recalculateRow, width: "14%" })) : (React.createElement(Table.CurrencyColumn, { accessorKey: 'revenue', header: formatMessage({ id: 'deliverable.columns.revenue' }), align: Table.Align.right, currency: currency, visible: eyeOptionMap ? eyeOptionMap['expense_estimates.revenue'] : true })),
        editMode ? (React.createElement(Table.NumberInputColumn, { accessorKey: 'cost', header: formatMessage({ id: 'deliverable.columns.cost' }), onInputChange: recalculateRow, width: "14%" })) : (React.createElement(Table.CurrencyColumn, { accessorKey: 'cost', header: formatMessage({ id: 'deliverable.columns.cost' }), align: Table.Align.right, currency: currency, visible: eyeOptionMap ? eyeOptionMap['expense_estimates.cost'] : true })),
        editMode ? (React.createElement(Table.NumberInputColumn, { accessorKey: 'markup', header: formatMessage({ id: 'deliverable.columns.markup' }), onInputChange: recalculateRow, width: "14%" })) : (React.createElement(Table.PercentageColumn, { accessorKey: 'markup', header: formatMessage({ id: 'deliverable.columns.markup' }), align: Table.Align.right, visible: eyeOptionMap ? eyeOptionMap['expense_estimates.markup'] : true })),
        React.createElement(Table.CurrencyColumn, { accessorKey: 'profit', header: formatMessage({ id: 'deliverable.columns.profit' }), align: Table.Align.right, currency: currency, width: "14%", visible: eyeOptionMap ? eyeOptionMap['expense_estimates.profit'] : true }),
        React.createElement(Table.PercentageColumn, { accessorKey: 'margin', header: formatMessage({ id: 'deliverable.columns.margin' }), align: Table.Align.right, width: "14%", visible: eyeOptionMap ? eyeOptionMap['expense_estimates.margin'] : true }),
        React.createElement(Table.ActionColumn, { header: 'contextmenu', hideHeader: true, hidden: !editMode },
            React.createElement(Table.ActionColumn.Option, { "data-cy": 'delete-baseline-expense', onClick: onDeleteRow }, formatMessage({ id: 'deliverable.columns.delete_expense_estimate' })))));
});
