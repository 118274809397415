import React from 'react';
import styled from 'styled-components/macro';
import { Button, FlexColumn, FlexRow, Icon, Modal, Text, ExpandableContainer, Frame } from '@forecast-it/design-system';
import { MODAL_TYPE, showModal } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import RichTextItem from '../richtext_item';
import { useIntl } from 'react-intl';
import Util from '../../forecast-app/shared/util/util';
export const RichTextWrapper = styled.div `
	overflow: hidden;
	.editor {
		padding: 8px 0 0 0;
		margin: 0;
		.public-DraftEditor-content {
			min-height: 0px;
		}
	}
	.DraftEditor-editorContainer,
	.DraftEditor-root {
		position: unset;
		border-left-width: 0;
	}
`;
const ManageDeliverablesModal = ({ closeModal, deliverables, projectId, baselineItems, }) => {
    const intl = useIntl();
    const onEditClick = (deliverable) => {
        closeModal();
        showModal({
            type: MODAL_TYPE.DELIVERABLE_EDITOR_MODAL,
            deliverables,
            deliverable,
            action: 'edit',
            projectId,
            baselineItems,
        });
    };
    const onCreateClick = () => {
        closeModal();
        showModal({
            type: MODAL_TYPE.DELIVERABLE_EDITOR_MODAL,
            deliverables,
            action: 'create',
            projectId,
            baselineItems,
        });
    };
    return (React.createElement(Modal, { size: Modal.SIZE.EXTRA_LARGE, onCloseModal: closeModal, setHeight: true },
        React.createElement(Modal.Title, null,
            intl.formatMessage({ id: 'deliverables.guide_section.deliverables.button' }),
            " "),
        React.createElement(Modal.Content, null,
            React.createElement(Button, { emphasis: 'medium', onClick: onCreateClick }, intl.formatMessage({ id: 'deliverables.manage.modal.add_new_deliverable' })),
            React.createElement(FlexColumn, null,
                React.createElement(FlexRow, null,
                    React.createElement(Icon, { icon: "deliverable" }),
                    React.createElement(Text, null, `Deliverables (${deliverables.length})`)),
                deliverables.length === 0 ? (React.createElement(Frame, { height: 's', centerContent: true },
                    React.createElement(Text, { size: '2' }, intl.formatMessage({ id: 'deliverables.manage.modal.no_deliverables' })))) : (React.createElement(FlexColumn, null, deliverables.map(deliverable => {
                    var _a, _b, _c, _d;
                    return (React.createElement(ExpandableContainer, { primaryText: ((_a = deliverable === null || deliverable === void 0 ? void 0 : deliverable.node) === null || _a === void 0 ? void 0 : _a.name) || '', secondaryText: ((_b = deliverable === null || deliverable === void 0 ? void 0 : deliverable.node) === null || _b === void 0 ? void 0 : _b.description)
                            ? Util.ConvertDraftJsToPlainText((_c = deliverable === null || deliverable === void 0 ? void 0 : deliverable.node) === null || _c === void 0 ? void 0 : _c.description)
                            : '', onActionClick: () => (deliverable === null || deliverable === void 0 ? void 0 : deliverable.node) && onEditClick(deliverable === null || deliverable === void 0 ? void 0 : deliverable.node), isExpandable: true },
                        React.createElement(RichTextWrapper, null,
                            React.createElement(RichTextItem, { alwaysShowControls: false, readOnly: true, text: (_d = deliverable === null || deliverable === void 0 ? void 0 : deliverable.node) === null || _d === void 0 ? void 0 : _d.description }))));
                }))))),
        deliverables.length > 0 && (React.createElement(Modal.Footer, null,
            React.createElement(React.Fragment, null)))));
};
export default ManageDeliverablesModal;
