import React, { useEffect } from 'react';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
import { ButtonDropdown, DropdownList, Text } from '@forecast-it/design-system';
import { usePDF } from '@react-pdf/renderer';
import styled from 'styled-components';
const FullHref = styled.a `
	width: 100%;
	height: 100%;
`;
export const DeliverablesPhasesExportButton = ({ pdf, exportCSV }) => {
    const [instance, setPDFDocument] = usePDF({ document: pdf.document });
    useEffect(() => setPDFDocument(pdf.document), [pdf.document]);
    const onExportCsv = () => {
        trackEvent('CSV File', 'Exported');
        exportCSV();
    };
    const onExportPdf = () => {
        trackEvent('PDF File', 'Exported');
    };
    return (React.createElement(DropdownList, null,
        React.createElement(DropdownList.Trigger, null,
            React.createElement(ButtonDropdown, { emphasis: 'medium', icon: 'download', "data-cy": 'export-phases' })),
        React.createElement(DropdownList.Content, { offset: 4, width: "150px" },
            React.createElement(DropdownList.Item, { onClick: onExportCsv, "data-cy": 'export-csv' },
                React.createElement(Text, null, "Export CSV")),
            React.createElement(DropdownList.Item, { "data-cy": 'export-pdf' },
                React.createElement(FullHref, { href: instance.url, download: pdf.fileName, onClick: onExportPdf },
                    React.createElement(Text, null, "Export PDF"))))));
};
export default DeliverablesPhasesExportButton;
