import { DropdownList, FlexColumn, FlexRow, Heading, SplitButton } from '@forecast-it/design-system';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Styled from 'styled-components/macro';
import { LoadMore } from '../../components/loaders/LoadMore';
import FinancialCalculationTrigger, { financialCalculationTriggerQuery, } from '../../components/new-ui/project/project-budget-v3/loaders/FinancialCalculationTrigger';
import { createProjectUpdateToast } from '../../components/new-ui/project/project-budget-v3/util/BudgetUtils';
import { EVENT_ID, subscribe, unsubscribe } from '../../containers/event_manager';
import { projectUrl } from '../../directApi';
import ChangeViewButton from '../../forecast-app/shared/components/changeview/ChangeViewButton';
import { MODAL_TYPE, showModal } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import { useEyeOptions } from '../../forecast-app/shared/hooks/useEyeOptions';
import DeliverablesPhasesExportButton from './DeliverablesPhasesExportButton';
import { PhaseCard } from './PhaseDeliverablesCard';
import { createNodeIdLookup, EXPORT_COLUMNS, formatAndExportCSV, getInitialBaselineItems, getInitialPhaseDeliverables, PHASE_EYE_OPTIONS, } from './Util';
import { ContentContainer } from '../../components/initial-plan/InitialPlan.styled';
import HeaderSection from '../../components/initial-plan/HeaderSection';
const DeliverablesPhasesSectionWrapper = Styled.div `
	padding: 0 32px;
`;
const StyledHeaderRow = Styled.div `
	padding: 8px 0;
`;
export const DeliverablesPhasesSection = ({ project, company, pdfProps, pageRetry, currencySymbol, }) => {
    var _a, _b, _c, _d;
    const intl = useIntl();
    const history = useHistory();
    const currency = ((project === null || project === void 0 ? void 0 : project.rateCard) ? project === null || project === void 0 ? void 0 : project.rateCard.currency : company === null || company === void 0 ? void 0 : company.currency) || '';
    const navigateToBaselineEdit = () => {
        history.push(`${projectUrl(project === null || project === void 0 ? void 0 : project.companyProjectId, project === null || project === void 0 ? void 0 : project.customProjectId)}/baseline/edit`);
    };
    const [retryCallback, setRetryCallback] = useState();
    const [retryProcessing, setRetryProcessing] = useState(false);
    const [retryProps, setRetryProps] = useState();
    const [financialCalculationTriggerTimestamp, setFinancialCalculationTriggerTimestamp] = useState();
    const updateProject = props => {
        if (retryCallback) {
            retryCallback();
            setRetryProps(props);
            setRetryProcessing(true);
        }
    };
    const handleRetryCompletion = result => {
        if (!result.isSmallProject && !(retryProps === null || retryProps === void 0 ? void 0 : retryProps.noToast)) {
            createProjectUpdateToast(intl, result.duration);
        }
    };
    const handleFinancialCalculationTriggerResponse = (retry, result) => {
        if (!retryCallback) {
            setRetryCallback(() => retry);
        }
        if (retryProcessing && result.timestamp !== financialCalculationTriggerTimestamp) {
            handleRetryCompletion(result);
            setRetryProcessing(false);
        }
    };
    useEffect(() => {
        if (sessionStorage.getItem('baselineProjectUpdate') === 'true') {
            sessionStorage.removeItem('baselineProjectUpdate');
            setRetryProcessing(true);
        }
        subscribe(EVENT_ID.BUDGET_UPDATE_PROJECT, updateProject);
        return () => {
            unsubscribe(EVENT_ID.BUDGET_UPDATE_PROJECT, updateProject);
        };
    });
    const phases = ((_a = project === null || project === void 0 ? void 0 : project.phases) === null || _a === void 0 ? void 0 : _a.edges) || [];
    const phaseLookup = createNodeIdLookup(phases.map(d => d === null || d === void 0 ? void 0 : d.node));
    const deliverableLookup = createNodeIdLookup((_d = (_c = (_b = project.deliverables) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.map(d => d === null || d === void 0 ? void 0 : d.node)) !== null && _d !== void 0 ? _d : []);
    const [initialBaselineItems, initialPhaseDeliverables] = useMemo(() => [getInitialBaselineItems(project), getInitialPhaseDeliverables(project)], [project]);
    const EYE_OPTIONS_LOCALSTORAGE_KEY = 'phase-management-eye-options';
    const [eyeOptionMap, setEyeOptionMap] = useState();
    const [, , eyeOptions, handleEyeOptionsChange] = useEyeOptions(PHASE_EYE_OPTIONS, EYE_OPTIONS_LOCALSTORAGE_KEY, undefined, setEyeOptionMap);
    const toggledColumnNames = useMemo(() => {
        return Object.entries(eyeOptionMap || {})
            .filter(([, toggled]) => toggled)
            .map(([path]) => path.split('.').pop())
            .reduce((cols, col) => (cols.includes(col) ? cols : [...cols, col]), []);
    }, [eyeOptionMap]);
    const exportCSV = useCallback(() => {
        const filename = `${project.name}_deliverables_baseline`;
        const columnNames = [...EXPORT_COLUMNS, ...toggledColumnNames];
        formatAndExportCSV(columnNames, filename, initialBaselineItems, phaseLookup, deliverableLookup);
    }, [eyeOptionMap, initialBaselineItems, phaseLookup, deliverableLookup]);
    const openShowHideColumnsModal = () => {
        showModal({
            type: MODAL_TYPE.SHOW_HIDE_COLUMNS,
            eyeOptions,
            onEyeOptionsChange: handleEyeOptionsChange,
        });
    };
    const showManagePhasesModal = () => {
        var _a;
        showModal({
            type: MODAL_TYPE.PHASE_MANAGEMENT_MODAL,
            initialPhases: (_a = project === null || project === void 0 ? void 0 : project.phases) === null || _a === void 0 ? void 0 : _a.edges,
            project,
            baselineItems: initialBaselineItems,
        });
    };
    const showManageDeliverablesModal = () => {
        var _a;
        showModal({
            type: MODAL_TYPE.MANAGE_DELIVERABLES_MODAL,
            deliverables: (_a = project.deliverables) === null || _a === void 0 ? void 0 : _a.edges,
            projectId: project.id,
            baselineItems: initialBaselineItems,
        });
    };
    const editDeliverableDropdownOptions = [
        {
            name: intl.formatMessage({ id: 'deliverables.manage.modal.manage_deliverables' }),
            onClick: showManageDeliverablesModal,
        },
        {
            name: intl.formatMessage({ id: 'deliverables.guide_section.phases.button' }),
            onClick: showManagePhasesModal,
        },
    ];
    const totalFinancialNumbers = project.financialNumbers
        ? {
            totalBaselineRevenue: project.financialNumbers.baselineRevenue,
            totalBaselineTimeAndExpenses: project.financialNumbers.baselineTimeAndExpenses,
            totalCost: project.financialNumbers.baselineCost,
            totalProfit: project.financialNumbers.baselineProfit,
            totalMargin: project.financialNumbers.baselineMargin,
        }
        : {
            totalBaselineRevenue: 0,
            totalBaselineTimeAndExpenses: 0,
            totalCost: 0,
            totalProfit: 0,
            totalMargin: 0,
        };
    return (React.createElement(LoadMore, { key: "financial-calculation-trigger", query: financialCalculationTriggerQuery, variables: {
            projectId: project.id,
        }, loader: React.createElement(FinancialCalculationTrigger, null) }, (result, financialTriggerRetry) => {
        handleFinancialCalculationTriggerResponse(financialTriggerRetry, result);
        if (financialCalculationTriggerTimestamp !== result.timestamp) {
            setFinancialCalculationTriggerTimestamp(result.timestamp);
            pageRetry();
        }
        return (React.createElement(CustomScrollDiv, { horizontalcontent: true },
            React.createElement(ContentContainer, null,
                React.createElement(DeliverablesPhasesSectionWrapper, null,
                    React.createElement(HeaderSection, { project: project, currencySymbol: currencySymbol, totalFinancialNumbers: totalFinancialNumbers }),
                    React.createElement(StyledHeaderRow, null,
                        React.createElement(FlexRow, { justifyContent: 'space-between', gap: 's' },
                            React.createElement(Heading, { size: 4 }, intl.formatMessage({ id: 'baseline.work_items' })),
                            React.createElement(FlexRow, { justifyContent: 'flex-end' },
                                React.createElement(DeliverablesPhasesExportButton, { pdf: pdfProps, exportCSV: exportCSV }),
                                React.createElement(ChangeViewButton, null,
                                    React.createElement(DropdownList.ContentGroup, { title: intl.formatMessage({ id: 'change_view.table_visibility' }) },
                                        React.createElement(DropdownList.Item, { onClick: openShowHideColumnsModal }, intl.formatMessage({ id: 'change_view.show_hide_columns' })))),
                                React.createElement(SplitButton, null,
                                    React.createElement(SplitButton.Section, { text: intl.formatMessage({ id: 'baseline.edit_baseline.title' }), "data-cy": 'export-phases', emphasis: 'high', onClick: navigateToBaselineEdit }),
                                    React.createElement(SplitButton.Section, { icon: 'chevronDown', emphasis: 'high', dropdownMenuOptions: editDeliverableDropdownOptions }))))),
                    React.createElement(FlexColumn, { gap: "s" }, phases
                        .map(phaseEdge => {
                        if (phaseEdge && phaseEdge.node && company) {
                            const phase = phaseEdge.node;
                            const items = initialBaselineItems
                                .filter(item => item.phaseId === phase.id)
                                .map((item, index) => (Object.assign(Object.assign({}, item), { index })));
                            return (React.createElement(PhaseCard, { phase: phase, currency: currency, company: company, phaseBaselineItems: items, project: project, projectPhaseDeliverables: initialPhaseDeliverables, eyeOptionMap: eyeOptionMap }));
                        }
                        return null;
                    })
                        .filter(Boolean))))));
    }));
};
