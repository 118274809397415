import Util from '../../forecast-app/shared/util/util';
import { encodeGlobalId } from '../../forecast-app/shared/util/GlobalIdUtil';
export const PHASE_COLUMNS = {
    PHASE_NAME: 'phase_name',
    ROLE: 'role',
    EXPENSE_NAME: 'expense_name',
    EXPENSE_CATEGORY: 'expense_category',
    DELIVERABLE_NAME: 'deliverable_name',
    VALUE_OF_SERVICE: 'value_of_service',
    REVENUE: 'revenue',
    COST: 'cost',
    MARKUP: 'markup',
    PROFIT: 'profit',
    MARGIN: 'margin',
    RATE_PER_HOUR: 'rate_per_hour',
    COST_PER_HOUR: 'cost_per_hour',
};
export const EXPORT_COLUMNS = [
    PHASE_COLUMNS.PHASE_NAME,
    PHASE_COLUMNS.DELIVERABLE_NAME,
    PHASE_COLUMNS.ROLE,
    PHASE_COLUMNS.EXPENSE_NAME,
    PHASE_COLUMNS.EXPENSE_CATEGORY,
];
const TIME_ESTIMATES_COLUMNS = [
    PHASE_COLUMNS.VALUE_OF_SERVICE,
    PHASE_COLUMNS.REVENUE,
    PHASE_COLUMNS.COST,
    PHASE_COLUMNS.PROFIT,
    PHASE_COLUMNS.RATE_PER_HOUR,
    PHASE_COLUMNS.COST_PER_HOUR,
];
const EXPENSE_ESTIMATES_COLUMNS = [
    PHASE_COLUMNS.REVENUE,
    PHASE_COLUMNS.COST,
    PHASE_COLUMNS.MARKUP,
    PHASE_COLUMNS.PROFIT,
    PHASE_COLUMNS.MARGIN,
];
const TIME_ESTIMATES = 'time_estimates';
const EXPENSE_ESTIMATES = 'expense_estimates';
const EYE_OPTION_COLUMNS = [
    { id: TIME_ESTIMATES, nestedOptions: TIME_ESTIMATES_COLUMNS },
    { id: EXPENSE_ESTIMATES, nestedOptions: EXPENSE_ESTIMATES_COLUMNS },
];
export const PHASE_EYE_OPTIONS = EYE_OPTION_COLUMNS.map(column => ({
    name: column.id,
    translationId: 'phase_management.column.' + column.id,
    disabled: false,
    checked: true,
    nestedOptions: column.nestedOptions.map(nestedColumn => ({
        name: nestedColumn,
        translationId: 'phase_management.column.' + nestedColumn,
        disabled: false,
        checked: true,
    })),
}));
export const getInitialBaselineItems = (project) => {
    var _a, _b, _c, _d;
    return [
        ((_b = (_a = project === null || project === void 0 ? void 0 : project.phaseBaselineRoles) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.filter(role => { var _a, _b; return ((_a = role === null || role === void 0 ? void 0 : role.node) === null || _a === void 0 ? void 0 : _a.role) && ((_b = role === null || role === void 0 ? void 0 : role.node) === null || _b === void 0 ? void 0 : _b.financialNumbers); }).map(role => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            const { baselineRevenue, baselineCost, baselineMinutes, baselineProfit, baselineTimeAndExpenses, baselineCostPerHour, baselineRatePerHour, } = ((_a = role === null || role === void 0 ? void 0 : role.node) === null || _a === void 0 ? void 0 : _a.financialNumbers) || {};
            const revenueRaw = baselineRevenue || 0;
            const costRaw = baselineCost || 0;
            const hoursRaw = (baselineMinutes || 0) / 60;
            const profitRaw = baselineProfit || 0;
            const valueOfServiceRaw = baselineTimeAndExpenses || 0;
            const [hours, valueOfService, revenue, cost, profit, ratePerHour, costPerHour] = [
                hoursRaw,
                valueOfServiceRaw,
                revenueRaw,
                costRaw,
                profitRaw,
                baselineRatePerHour,
                baselineCostPerHour,
            ].map(Util.roundFloatToTwoDecimals);
            const globalDeliverableId = ((_b = role === null || role === void 0 ? void 0 : role.node) === null || _b === void 0 ? void 0 : _b.deliverableId)
                ? encodeGlobalId('DeliverableType', Number((_c = role === null || role === void 0 ? void 0 : role.node) === null || _c === void 0 ? void 0 : _c.deliverableId))
                : '';
            return {
                phaseBaselineRoleId: ((_d = role === null || role === void 0 ? void 0 : role.node) === null || _d === void 0 ? void 0 : _d.id) || '',
                projectId: project.id || '',
                phaseId: ((_e = role === null || role === void 0 ? void 0 : role.node) === null || _e === void 0 ? void 0 : _e.globalPhaseId) || '',
                deliverableId: globalDeliverableId,
                roleId: ((_g = (_f = role === null || role === void 0 ? void 0 : role.node) === null || _f === void 0 ? void 0 : _f.role) === null || _g === void 0 ? void 0 : _g.id) || '',
                roleName: ((_j = (_h = role === null || role === void 0 ? void 0 : role.node) === null || _h === void 0 ? void 0 : _h.role) === null || _j === void 0 ? void 0 : _j.name) || '',
                hours,
                valueOfService,
                revenue,
                cost,
                profit,
                ratePerHour,
                costPerHour,
            };
        })) || [],
        ((_d = (_c = project === null || project === void 0 ? void 0 : project.phaseBaselineExpenses) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.filter(expense => expense === null || expense === void 0 ? void 0 : expense.node).map(expense => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            const revenueRaw = ((_a = expense === null || expense === void 0 ? void 0 : expense.node) === null || _a === void 0 ? void 0 : _a.expenseRevenue) || 0;
            const costRaw = ((_b = expense === null || expense === void 0 ? void 0 : expense.node) === null || _b === void 0 ? void 0 : _b.expenseCost) || 0;
            const [roundedRevenue, roundedCost, roundedMarkup, roundedProfit, roundedMargin] = [
                revenueRaw,
                costRaw,
                ((_c = expense === null || expense === void 0 ? void 0 : expense.node) === null || _c === void 0 ? void 0 : _c.expenseMarkup) || 0,
                ((_d = expense === null || expense === void 0 ? void 0 : expense.node) === null || _d === void 0 ? void 0 : _d.expenseProfit) || 0,
                revenueRaw ? (revenueRaw - costRaw) / revenueRaw : 0,
            ].map(Util.roundFloatToTwoDecimals);
            const name = ((_e = expense === null || expense === void 0 ? void 0 : expense.node) === null || _e === void 0 ? void 0 : _e.expenseName) || null;
            const categoryName = ((_f = expense === null || expense === void 0 ? void 0 : expense.node) === null || _f === void 0 ? void 0 : _f.expenseCategory) ? (_g = expense === null || expense === void 0 ? void 0 : expense.node) === null || _g === void 0 ? void 0 : _g.expenseCategory.name : null;
            const categoryId = ((_h = expense === null || expense === void 0 ? void 0 : expense.node) === null || _h === void 0 ? void 0 : _h.expenseCategory) ? (_j = expense === null || expense === void 0 ? void 0 : expense.node) === null || _j === void 0 ? void 0 : _j.expenseCategory.id : null;
            const globalDeliverableId = ((_k = expense === null || expense === void 0 ? void 0 : expense.node) === null || _k === void 0 ? void 0 : _k.deliverableId)
                ? encodeGlobalId('DeliverableType', Number((_l = expense === null || expense === void 0 ? void 0 : expense.node) === null || _l === void 0 ? void 0 : _l.deliverableId))
                : '';
            return {
                phaseBaselineExpenseId: ((_m = expense === null || expense === void 0 ? void 0 : expense.node) === null || _m === void 0 ? void 0 : _m.id) || '',
                projectId: project.id || '',
                phaseId: ((_o = expense === null || expense === void 0 ? void 0 : expense.node) === null || _o === void 0 ? void 0 : _o.globalPhaseId) || '',
                deliverableId: globalDeliverableId,
                categoryName,
                categoryId,
                revenue: roundedRevenue,
                cost: roundedCost,
                markup: roundedMarkup,
                profit: roundedProfit,
                margin: roundedMargin,
                name,
            };
        })) || [],
    ]
        .flat(2)
        .filter((item) => !!item) || [];
};
export const getInitialPhaseDeliverables = (project) => {
    var _a, _b;
    return ((_b = (_a = project === null || project === void 0 ? void 0 : project.phases) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(phaseEdge => {
        var _a, _b, _c;
        return (_c = (_b = (_a = phaseEdge === null || phaseEdge === void 0 ? void 0 : phaseEdge.node) === null || _a === void 0 ? void 0 : _a.phaseDeliverables) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.filter(phaseDeliverableEdge => (phaseDeliverableEdge === null || phaseDeliverableEdge === void 0 ? void 0 : phaseDeliverableEdge.node) && (phaseDeliverableEdge === null || phaseDeliverableEdge === void 0 ? void 0 : phaseDeliverableEdge.node.deliverable)).map(phaseDeliverableEdge => {
            var _a, _b, _c, _d;
            return ({
                id: (_a = phaseDeliverableEdge === null || phaseDeliverableEdge === void 0 ? void 0 : phaseDeliverableEdge.node) === null || _a === void 0 ? void 0 : _a.id,
                phaseId: (_b = phaseDeliverableEdge === null || phaseDeliverableEdge === void 0 ? void 0 : phaseDeliverableEdge.node) === null || _b === void 0 ? void 0 : _b.phaseId,
                deliverable: (_c = phaseDeliverableEdge === null || phaseDeliverableEdge === void 0 ? void 0 : phaseDeliverableEdge.node) === null || _c === void 0 ? void 0 : _c.deliverable,
                financialNumbers: (_d = phaseDeliverableEdge === null || phaseDeliverableEdge === void 0 ? void 0 : phaseDeliverableEdge.node) === null || _d === void 0 ? void 0 : _d.financialNumbers,
            });
        });
    }).flat(2).filter((item) => !!item)) || [];
};
export const createNodeIdLookup = (nodes) => new Map(nodes.filter(Boolean).map((d) => [d.id, d]));
export function formatAndExportCSV(columnNames, filename, initialBaselineItems, phaseLookup, deliverableLookup) {
    const fullFileName = filename + '.csv';
    const mappedRows = initialBaselineItems.map((item) => {
        var _a, _b;
        const mappings = {
            [PHASE_COLUMNS.PHASE_NAME]: (_a = phaseLookup.get(item.phaseId)) === null || _a === void 0 ? void 0 : _a.name,
            [PHASE_COLUMNS.DELIVERABLE_NAME]: (_b = deliverableLookup.get(item.deliverableId)) === null || _b === void 0 ? void 0 : _b.name,
            [PHASE_COLUMNS.ROLE]: item.roleName,
            [PHASE_COLUMNS.VALUE_OF_SERVICE]: item.valueOfService,
            [PHASE_COLUMNS.REVENUE]: item.revenue,
            [PHASE_COLUMNS.COST]: item.cost,
            [PHASE_COLUMNS.PROFIT]: item.profit,
            [PHASE_COLUMNS.RATE_PER_HOUR]: item.ratePerHour,
            [PHASE_COLUMNS.COST_PER_HOUR]: item.costPerHour,
            [PHASE_COLUMNS.EXPENSE_NAME]: item.name,
            [PHASE_COLUMNS.EXPENSE_CATEGORY]: item.categoryName,
            [PHASE_COLUMNS.MARKUP]: item.markup,
            [PHASE_COLUMNS.MARGIN]: item.margin,
        };
        return columnNames.map(header => {
            var _a;
            const value = `${(_a = mappings[header]) !== null && _a !== void 0 ? _a : ''}`;
            return value.includes(',') ? `"${value}"` : value;
        });
    });
    const exportData = [columnNames, ...mappedRows].join('\r\n');
    Util.exportToCSV(exportData, fullFileName);
}
