import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { FILTER_SECTION, FILTER_TYPE } from '../../constants.js';
import { FILTER_SECTIONS } from '../../forecast-app/shared/components/filters/dropdown_section.js';
import FilterV4 from '../../forecast-app/shared/components/filters/FilterWrapper.js';
const LOCALSTORAGE_FILTERS_KEY = 'expense-management-filter';
export const getInitialExpenseManagementFilters = () => {
    const storedValue = localStorage.getItem(LOCALSTORAGE_FILTERS_KEY);
    return storedValue ? JSON.parse(storedValue) : '';
};
const ExpenseManagementFilterButton = ({ viewer, setFilterFunctions }) => {
    const onFilterChange = (_, filterFunctions) => {
        setFilterFunctions(filterFunctions);
    };
    const expenseFilters = [
        FILTER_TYPE.EXPENSE_CATEGORY,
        FILTER_TYPE.PERSON,
        FILTER_TYPE.APPROUVED,
        FILTER_TYPE.EXPENSE_BILLING_OPTIONS,
    ];
    const projectFilters = [
        FILTER_TYPE.PROJECT,
        FILTER_TYPE.PROJECT_STATUS,
        FILTER_TYPE.CLIENT,
        FILTER_TYPE.PROJECT_TYPE,
        FILTER_TYPE.CONTACT,
    ];
    return (React.createElement(FilterV4, { key: `filter-v4-component-1`, expenseFilters: expenseFilters, projectFilters: projectFilters, viewer: viewer, onFiltersChange: onFilterChange, appliedFiltersName: LOCALSTORAGE_FILTERS_KEY, filterSection: FILTER_SECTION.EXPENSE_MANAGEMENT, defaultSection: FILTER_SECTIONS.EXPENSES, cy: 'expense_management_filter', enableFilterMode: true, useDesignSystemButton: true }));
};
const ExpenseManagementFilterButtonContainer = createFragmentContainer(ExpenseManagementFilterButton, {
    viewer: graphql `
		fragment ExpenseManagementFilterButton_viewer on Viewer {
			filters(first: 1000000, filterSection: INVOICING_INVOICES) @connection(key: "Viewer_filters", filters: []) {
				edges {
					node {
						id
						name
						section
						value
					}
				}
			}
		}
	`,
});
export default ExpenseManagementFilterButtonContainer;
