import React from 'react';
import BaselinePDFSummaryPhase from './BaselinePDFSummaryPhase';
import { TextHeaderSecondary, TextMain, TextTotal, ViewLine, ViewSection } from './layout/PDFLayout';
const BaselinePDFSummary = ({ phases, projectTotal, intl: { formatMessage } }) => {
    return (React.createElement(ViewSection, { style: { backgroundColor: '#E5F4FF' } },
        React.createElement(TextHeaderSecondary, null, formatMessage({ id: 'baseline.pdf.price_summary' })),
        React.createElement(ViewLine, null,
            React.createElement(TextMain, { bold: true, style: { width: '80%' } }, formatMessage({ id: 'common.phase_name' })),
            React.createElement(TextMain, { bold: true, style: { textAlign: 'right', width: '20%' } }, formatMessage({ id: 'baseline.pdf.phase_total' }))),
        phases
            ? phases.map(phase => {
                return React.createElement(BaselinePDFSummaryPhase, { key: phase.id, phase: phase });
            })
            : null,
        React.createElement(ViewLine, { noBorder: true },
            React.createElement(TextMain, { style: { width: '60%' } }),
            React.createElement(TextTotal, { style: { textAlign: 'right', width: '20%' } }, formatMessage({ id: 'baseline.pdf.grand_total' })),
            React.createElement(TextTotal, { style: { textAlign: 'right', width: '20%' } }, projectTotal))));
};
export default BaselinePDFSummary;
