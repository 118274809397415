import React from 'react';

import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE, FILTER_SECTION, FILTER_TYPE} from '../../../constants';
import {
	getVisualizationMode,
	hasSchedulingAccess,
	isSimulationMode,
	UTILIZATION_FORMAT,
	VISUALIZATION_MODE,
} from '../canvas-timeline/canvas_timeline_util';
import HeaderBar from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {dispatch, EVENT_ID} from '../../../containers/event_manager';
import Util from '../../../forecast-app/shared/util/util';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/FilterWrapper';
import {getFiltersAlphabetically} from '../../../forecast-app/shared/components/filters/filter_util';
import {onCreateShare} from './share/CreateShare';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {
	onToggleCalcWin,
	onToggleHideHard,
	onToggleHideSoft,
} from '../components/allocation_controls/AllocationControlsCanvasUtils';
import {getFilterString} from './projects_scheduling_util';
import {TopHeaderBar} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import EventManager from '../EventManager';
import CompanySetupUtil from '../../../forecast-app/shared/util/CompanySetupUtil';
import {customOrCompanyIdMatches} from '../utils';

const onPrint = () => {
	dispatch(EVENT_ID.CANVAS_TIMELINE_PRINT);
};

const mapFilterV4ViewerDataToGraphQLType = pageComponent => {
	const {projectFilters, personFilters, projectTimelineFilters} = pageComponent.getFilterData();

	return {
		filters: {edges: [...personFilters, ...projectFilters, ...projectTimelineFilters].map(filter => ({node: filter}))},
	};
};

export const getTopHeaderContent = isUsingProjectAllocation => {
	const content = [];
	const onboardingFlows = [
		{
			id: 'all-timelines-introduction',
			title: 'Introduction to this page',
			description: null,
			contentId: '1681897191gQff3989',
		},
		{
			id: 'all-timelines-add-project-team',
			title: 'How to add a project team',
			description: null,
			contentId: '1681898314zArd8572',
		},
		...(isUsingProjectAllocation && hasPermission(PERMISSION_TYPE.ALLOCATION_CREATE)
			? [
					{
						id: 'all-timelines-add-placeholder',
						title: 'How to add placeholder allocations',
						description: null,
						contentId: '1681898641uPmn6082',
					},
			  ]
			: []),
		{
			id: 'all-timelines-filters',
			title: 'How to use the filters on this page',
			description: null,
			contentId: '1681900041jPmy2281',
		},
	];
	const onboardingComponent = {
		id: 'onboarding-component',
		type: TopHeaderBar.TYPE.ONBOARDING,
		title: 'Learn to use All Timelines',
		options: onboardingFlows,
		helpCenterLink: 'https://support.forecast.app/hc/en-us/sections/4419344459665-Projects',
		subLink:
			'https://support.forecast.app/hc/en-us/articles/9962441149841-Viewing-your-projects-heatmap-Schedule-Projects-',
	};
	content.push(onboardingComponent);

	return content;
};

export const createHeader = (
	pageComponent,
	isProjectTimeline,
	projectTimelineCompanyProjectId = null,
	projectTimelineCompanyProjectGroupId = null,
	projectTimelineProjectId = null,
	projectTimelineProjectGroupId = null
) => {
	const {programPrefix} = pageComponent.props;
	const {eyeOptions, schedulingOptions} = pageComponent.state;
	const sharedContext = pageComponent.props.sharedContext;

	const {modules, company} = pageComponent.getFilterData();
	const formatMessage = pageComponent.props.intl.formatMessage;
	const isMixedAllocationModeEnabled = Util.isMixedAllocationModeEnabled(company);
	const companyProjectId = pageComponent.state.project?.companyProjectId?.toString() || '';

	const leftContent = [],
		rightContent = [];

	if (!isProjectTimeline) {
		leftContent.push({
			type: ELEMENT_TYPE.EMPTY_SPACE,
			width: 14,
		});
	}

	if (isProjectTimeline && !isMixedAllocationModeEnabled && !sharedContext && !programPrefix) {
		const dropdownOptions = [
			{
				value: UTILIZATION_FORMAT.HOURS,
				label: formatMessage({id: 'scheduling.heatmap_in_hours'}),
			},
			{
				value: UTILIZATION_FORMAT.PERCENTAGE,
				label: formatMessage({id: 'scheduling.heatmap_in_percentage'}),
			},
		];

		leftContent.push({
			type: ELEMENT_TYPE.DROPDOWN,
			dropdownOptions,
			value: schedulingOptions.utilizationFormat,
			callback: format => EventManager.onUtilizationFormatChange(pageComponent, format.value),
			cy: 'utilization-resource-format-changer',
		});
	}

	if (!sharedContext) {
		if (eyeOptions?.length > 0) {
			leftContent.push({
				type: ELEMENT_TYPE.THE_EYE,
				options: eyeOptions,
				onSelect: selected => EventManager.onEyeOptionsChange(pageComponent, selected),
				openRight: true,
				disableTooltip: true,
				disabled: true,
			});
		}

		leftContent.push({
			type: ELEMENT_TYPE.DEPENDENCY_MODE_SCHEDULING,
			className:
				'dependency-mode-button ' +
				(pageComponent.state.isDraggingWholeDependencyChainModeOn
					? 'dependency-mode-whole-chain'
					: 'dependency-mode-single-task'),
			onClick: pageComponent.toggleDependencyDragMode.bind(pageComponent),
			title: formatMessage({
				id: pageComponent.state.isDraggingWholeDependencyChainModeOn
					? 'scheduling.dependency_drag_mode_chain'
					: 'scheduling.dependency_drag_mode_single',
			}),
			cy: 'dependency-button',
		});
	}

	leftContent.push({
		type: ELEMENT_TYPE.EMPTY_SPACE,
		width: 93,
	});

	if (!sharedContext) {
		leftContent.push({type: ELEMENT_TYPE.EXPAND_ALL_CANVAS, cy: 'expand-all-button'});

		if (!programPrefix) {
			leftContent.push({
				type: ELEMENT_TYPE.SHARE,
				userpilot: 'project-schedule-share',
				disabled: !hasPermission(PERMISSION_TYPE.PROJECT_SCHEDULING_SHARE_CREATE),
				onClick: () =>
					onCreateShare(
						pageComponent.state.filters,
						pageComponent.state.eyeOptions,
						pageComponent.timeline.state.zoomLevel,
						companyProjectId,
						pageComponent.props.groupId
					),
			});
		}
	}

	leftContent.push({
		type: ELEMENT_TYPE.DATE_CHANGER,
		onlyTodayButton: true,
		handleTodayButtonClick: () => EventManager.onScrollToToday(pageComponent),
		handleMoveDateButtonClick: moveForward => EventManager.onMoveDateButtonClick(pageComponent, moveForward),
	});

	leftContent.push({
		type: ELEMENT_TYPE.ZOOM_MENU,
	});

	if (
		pageComponent.state.projectTimelineProjectStatus &&
		(pageComponent.state.projectTimelineProjectStatus === 'DONE' ||
			pageComponent.state.projectTimelineProjectStatus === 'HALTED')
	) {
		leftContent.push({
			type: ELEMENT_TYPE.INDICATOR,
			status: pageComponent.state.projectTimelineProjectStatus,
		});
	}

	rightContent.push({
		type: ELEMENT_TYPE.SEARCH,
		value: pageComponent.state.searchFilterValue || '',
		onChange: event => EventManager.onSearchChange(pageComponent, event),
		cy: 'canvas-search',
		placeholder: formatMessage({
			id: isProjectTimeline ? 'scheduling.search_task' : 'scheduling.search_project',
		}),
	});

	if (!sharedContext) {
		const hasFinancialModule = CompanySetupUtil.hasFinance();

		const projectFilters =
			isProjectTimeline && projectTimelineCompanyProjectId
				? []
				: [
						FILTER_TYPE.PROJECT,
						FILTER_TYPE.CLIENT,
						FILTER_TYPE.PROJECT_STAGE,
						FILTER_TYPE.PROJECT_STATUS,
						FILTER_TYPE.PRIORITY_LEVEL,
						FILTER_TYPE.LABEL,
						...(hasFeatureFlag('placeholders') ? [FILTER_TYPE.PROJECT_WIN_CHANCE] : []),
				  ];

		let personFilters =
			isProjectTimeline && !hasSchedulingAccess(pageComponent)
				? undefined
				: [FILTER_TYPE.PERSON, FILTER_TYPE.ROLE, FILTER_TYPE.PERMISSION_LEVEL, FILTER_TYPE.LABEL];
		const taskFilters = [
			FILTER_TYPE.PERSON,
			FILTER_TYPE.ROLE,
			FILTER_TYPE.CLIENT_GUEST_USERS,
			FILTER_TYPE.INDICATOR,
			FILTER_TYPE.RECENT_ACTIVITY,
			FILTER_TYPE.LABEL,
		];

		const filterSection = isProjectTimeline ? FILTER_SECTION.PROJECT_TIMELINE : FILTER_SECTION.SCHEDULING_PROJECTS;

		if (personFilters && Util.hasDepartments(modules)) {
			personFilters.push(FILTER_TYPE.DEPARTMENT);
		}

		if (personFilters && Util.hasSkills()) {
			personFilters.push(FILTER_TYPE.SKILL);
		}

		const filterV4Viewer = mapFilterV4ViewerDataToGraphQLType(pageComponent);

		if (personFilters && !isProjectTimeline) {
			personFilters.push(FILTER_TYPE.INTERNAL_TIME);
			personFilters.push(FILTER_TYPE.TIME_OFF);
		}

		if (personFilters && isProjectTimeline) {
			personFilters.splice(0, 1, FILTER_TYPE.PROJECT_PERSON);
			personFilters.push(FILTER_TYPE.TIME_OFF);
		}

		if (isProjectTimeline) {
			taskFilters.splice(2, 0, FILTER_TYPE.PROJECT_PHASE);
			taskFilters.splice(0, 1, FILTER_TYPE.PROJECT_PERSON);
			taskFilters.push(FILTER_TYPE.OWNER);
			taskFilters.push(FILTER_TYPE.PROJECT_FOLLOWER);
			taskFilters.push(FILTER_TYPE.PROJECT_STATUS_COLUMN);
		} else {
			taskFilters.push(FILTER_TYPE.STATUS_CATEGORY);
			taskFilters.push(FILTER_TYPE.OWNER);
			taskFilters.push(FILTER_TYPE.FOLLOWER);
			personFilters.splice(1, 0, FILTER_TYPE.TEAM);
			if (projectFilters.length) {
				projectFilters.splice(2, 0, FILTER_TYPE.PERSON);
			}
		}

		if (hasFinancialModule && !projectTimelineCompanyProjectId) {
			// only show the budget type if company has financial access and is not simple project timeline. We still show the budget filter if connected project
			projectFilters.splice(1, 0, FILTER_TYPE.PROJECT_TYPE);
		}

		// No person filters if using task allocation and on project scheduling.
		const isUsingProjectAllocation = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.ALLOCATION);
		const isUsingCombinationMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);

		if (!isUsingProjectAllocation && !isProjectTimeline) {
			personFilters = [];
		}

		const preselectedTab =
			isProjectTimeline && projectTimelineCompanyProjectId ? FILTER_SECTIONS.TASKS : FILTER_SECTIONS.PROJECTS;

		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			defaultSection: preselectedTab,
			operatorOptions: {allowExclude: true, allowRequireAll: true},
			projectFilters,
			peopleFilters: personFilters,
			taskFilters: getFiltersAlphabetically(taskFilters, formatMessage),
			primaryFilters: {
				[FILTER_SECTIONS.PEOPLE]: isProjectTimeline
					? [FILTER_TYPE.PROJECT_PERSON, FILTER_TYPE.ROLE, FILTER_TYPE.DEPARTMENT]
					: [FILTER_TYPE.PERSON],
				[FILTER_SECTIONS.PROJECTS]: isProjectTimeline
					? [FILTER_TYPE.PROJECT, FILTER_TYPE.PROJECT_STAGE]
					: [
							FILTER_TYPE.PROJECT_STAGE,
							FILTER_TYPE.PROJECT,
							FILTER_TYPE.LABEL,
							FILTER_TYPE.CLIENT,
							FILTER_TYPE.PERSON,
							FILTER_TYPE.PROJECT_TYPE,
					  ],
				[FILTER_SECTIONS.TASKS]: isProjectTimeline
					? [
							FILTER_TYPE.PROJECT_STATUS_COLUMN,
							FILTER_TYPE.PROJECT_PERSON,
							FILTER_TYPE.PROJECT_PHASE,
							FILTER_TYPE.LABEL,
							FILTER_TYPE.ROLE,
					  ]
					: [FILTER_TYPE.STATUS_CATEGORY, FILTER_TYPE.LABEL],
			},
			viewer: filterV4Viewer,
			appliedFiltersName: getFilterString(pageComponent),
			filterSection,
			onFiltersChange: (filterValues, filterFunctions) =>
				EventManager.onFilterChange(pageComponent, filterValues, filterFunctions),
			projectId: projectTimelineProjectId,
			projectGroupId: projectTimelineProjectGroupId,
			programPrefix: programPrefix,
			companyProjectId: projectTimelineCompanyProjectId,
			companyProjectGroupId: projectTimelineCompanyProjectGroupId,
			cy: isProjectTimeline ? 'project-schedule-filters' : 'projects-schedule-filters',
		});

		if (isUsingProjectAllocation || isUsingCombinationMode) {
			rightContent.push({
				type: ELEMENT_TYPE.WIN_PROBABILITY,
				calcWin: schedulingOptions.calcWin,
				onToggleCalcWin: checked => onToggleCalcWin(pageComponent, checked),
				cy: 'canvas-win-probability',
			});

			rightContent.push({
				type: ELEMENT_TYPE.ALLOCATION_CONTROLS,
				hideSoft: schedulingOptions.hideSoft,
				onToggleHideSoft: checked => onToggleHideSoft(pageComponent, checked),
				hideHard: schedulingOptions.hideHard,
				onToggleHideHard: checked => onToggleHideHard(pageComponent, checked),
				cy: 'canvas-allocation-controls',
			});
		}

		if (isProjectTimeline) {
			const project = pageComponent
				.getFilterData()
				.projects.find(project => customOrCompanyIdMatches(project, pageComponent.props.projectId));
			const timelineProject = isProjectTimeline && !pageComponent.props.groupId ? project : null;
			if (
				!isSimulationMode() &&
				project &&
				!project.isInProjectGroup &&
				hasFeatureFlag('auto_schedule_access') &&
				hasSchedulingAccess(pageComponent) &&
				timelineProject &&
				!['HALTED', 'DONE'].includes(timelineProject.status) &&
				!timelineProject.useManualAllocations
			) {
				const autoScheduleButton = {
					key: 'auto-schedule-button',
					type: ELEMENT_TYPE.BUTTON,
					customClassName: 'auto-scheduling-button',
					autoScheduleStyle: true,
					text: formatMessage({id: 'auto_scheduling.auto_schedule'}),
					callback: pageComponent.showAutoSchedulingModal.bind(pageComponent),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.AI_BLUE,
					dataCy: 'auto-schedule-button',
				};
				rightContent.push(autoScheduleButton);
			}
		}
	} else {
		rightContent.push({
			type: ELEMENT_TYPE.PRINT,
			onClick: onPrint,
		});
		rightContent.push({
			type: ELEMENT_TYPE.COPY_LINK,
			link: window.location.href,
		});
	}

	if (!isProjectTimeline) {
		return <HeaderBar scheduling={true} excludeLeftPadding={true} leftContent={leftContent} rightContent={rightContent} />;
	} else {
		return <HeaderBar leftContent={leftContent} rightContent={rightContent} />;
	}
};
