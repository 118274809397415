import Styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../css_variables';

export const InitialPlanPageWrapper = Styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	color:${CSS_CONSTANTS.v2_text_gray};
	.date-picker-button-container-v3 .date-picker-button.locked {
		opacity: 1;
	}
`;

export const SettingsPageWrapper = Styled.div`
	padding: 0 32px;
	width: 748px;
`;

export const ContentContainer = Styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;
export const BackNavigationWrapper = Styled.div`
	margin: 0 0 24px 32px;
	display:flex;
	flex-direction: column;
`;

export const BackNav = Styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 8px;
	div{
		font-size: 13px;
		color: #a1a1a1;
		font-weight: 500;
		margin: 0;
		padding: 0;
		display: flex
	}
	div {
		display: flex;
		margin-right: 4px;

	}
`;
export const PageTitle = Styled.h2`
	margin: 0;
	padding: 0,
	font-size: 24px;
	color: #535353;
	font-weight: 600;
`;
export const HeaderBarStyled = Styled.div`
	.header-bar {
		padding-left: ${props => (props.noPadding ? '0px' : '66px')};
		padding-right: ${props => (props.noPadding ? '0px' : '66px')};
		padding-bottom: 24px;
	}
`;

export const PhasesWrapper = Styled.div`
	//display: flex;
	//flex-direction:column;
	flex-grow: 1;
	margin-bottom: 75px;
	width: ${props => props.width}px;
	min-width: 100%;
`;

export const FlexRow = Styled.div`
	align-items: ${props => props.verticalAlign};
	display: flex;
	flex-direction: row;
	flex-grow: ${props => props.grow && '1'};
`;

export const Dates = Styled.div`
	display: flex;
	flex-grow: 1;
`;

export const Estimate = Styled.div``;
export const Revenue = Styled.div``;

export const AddItemWrapper = Styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 13px;
	height: 38px;
	padding:8px 16px 0 16px;
	border-top: 1px solid #e6e6e6;
	>div, >span{
		margin-right: 8px;
	}

	.assigned-dropdown-wrapper {
		input{
			height:30px;
		}
	}
`;

export const TextWrapper = Styled.div`
`;

export const PhaseContainer = Styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	box-sizing: border-box;
	margin-bottom: 10px;
	padding: 0 32px;
`;

export const InnerPhaseContainer = Styled.div`
	padding:10px;
	background:   ${CSS_CONSTANTS.color_medium_gray};
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
`;

export const CategoryWrapper = Styled.div`
	background:#FFFFFF;
	border: 1px solid  ${CSS_CONSTANTS.app_border_color};
	padding: 16px;
	&:first-child {
		margin: 0 0 10px 0;
	}
	
`;

export const Row = Styled.div`
	display: flex;
	flex-direction: row;
	padding: ${props => (props.noPadding ? 0 : '0 16px')} ;
	height: ${props => props.height && `${props.height}px`};
	padding:0 8px;

`;

export const StickySection = Styled.div`
	margin-bottom: ${props => props.bottomSpacing && '24px'};
	padding: 0 32px;
	position: sticky;
	left: 0;
`;

export const CategoryTitleWrapper = Styled(Row)`
	display:flex;
	//position: sticky;
	//top: ${props => props.topPosition && `${props.topPosition}px`};
	//left: ${props => props.leftPosition && `${props.leftPosition}px`};
	//z-index: 1;
	margin: ${props => props.isExpanded && '0 0 16px 0'};
`;

export const CaretWrapper = Styled.div`
	display:flex;
	flex-direction:row;
	align-items:stretch;
	margin: 0 4px 0 0;
`;

export const PhaseHeaderSection = Styled.div`
	display: flex;
	flex-direction: column;
	position: sticky;
	top: ${props => `${props.topPosition}px`};
	z-index: 2;
	width:100%;
	height: 40px;
	min-height: 40px;
	justify-content:center;
	color: ${props => props.color};
	background-color: ${props => props.backgroundColor};
	cursor: pointer;
	&:hover {
		filter: brightness(90%);
	}
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
`;

export const PhaseHeader = Styled(Row)`
	position: relative;
	padding: 0 10px;
`;

export const PhasesTitle = Styled(PhaseHeader)`
	position: sticky;
	top:0;
	z-index:3;
	line-height: 11px;
	padding: 0;
	margin: 0 32px;
	background:#FFFFFF;
`;

export const ColumnItem = Styled.div`
	display: flex;
	align-items: center;
	margin-left: 8px;
	min-width: ${props => (props.width ? `${props.width}px` : null)};
	width: ${props => (props.width ? `${props.width}px` : 'auto')};
	justify-content: ${props => {
		switch (props.align) {
			case 'left':
				return 'flex-start';
			case 'center':
				return 'center';
			case 'right':
				return 'flex-end';
			default:
				return 'flex-start';
		}
	}};
`;

const HeaderItem = Styled(ColumnItem)`
	display: flex;
	align-items: center;
	${props => (props.growable ? 'flex-grow: 1' : null)};
	justify-content: ${props => {
		switch (props.align) {
			case 'left':
				return 'flex-start';
			case 'center':
				return 'center';
			case 'right':
				return 'flex-end';
			default:
				return 'flex-start';
		}
	}};
	font-weight: 700;
	
`;
export const PhaseHeaderItem = Styled(HeaderItem)`
	font-size: 13px;
	line-height:16px;
	${props => (props.minWidth ? 'min-width: ' + props.minWidth + 'px' : null)};
	.date-picker-button-container-v3 .date-picker-button .date-text-container.baseline{
			font-size:13px;
	}
	input {
		font-weight: 700;
		font-size: 13px;
		padding:0px;
		width: 100%;
		max-width: 500px;
		padding-left: 0;
		border: solid 1px transparent;
		border-radius: 4px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		height:100%;
		cursor: text;
		color:${props => props.projectTextColor};
		background-color: ${props => props.projectColor};
		&:hover {
			background-color: rgba(0, 0, 0, 0.1);
			border-color:transparent;
		}
		&:focus {
			outline: none;
			background-color: rgba(0, 0, 0, 0.1);
			border-color:transparent;
		}
		&:disabled {
			cursor: default;
			&:hover {
				background: none;
				background-color: none !important;
			}
		}
		
	}
`;

export const ColumnTitle = Styled(ColumnItem)`
	font-size: 9px;
	color: #a1a1a1;
	padding-bottom: 5px;
	padding-left:${props => (props.paddingLeft ? props.paddingLeft + 'px' : null)};
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 500;
	${props => (props.growable ? 'flex-grow: 1' : null)};
`;

export const ColumnTitleItem = Styled(ColumnTitle)`
	padding-left:${props => (props.paddingLeft ? props.paddingLeft + 'px' : null)};
	color: ${props => props.color};
	text-align:${props => props.align}
`;

export const RoleRow = Styled(Row)`
	height: 50px;
	border-top: 1px solid #e6e6e6;
`;

export const RoleRowContainer = Styled.div`
	
`;

export const RoleRowItem = Styled(ColumnItem)`
	height: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	${props => (props.growable ? 'flex-grow: 1' : null)};
	justify-content: ${props => {
		switch (props.align) {
			case 'left':
				return 'flex-start';
			case 'center':
				return 'center';
			case 'right':
				return 'flex-end';
			default:
				return 'flex-start';
		}
	}};
`;

export const PhaseSubSeciontTitle = Styled.div`
	font-weight: 700;
	font-size:13px;
	color:#535353;
	//z-index:2;
	flex:1;
`;

export const DropdownWrapperStyled = Styled.div`
	max-width: 400px;
	width: ${props => (props.width ? `${props.width}px` : '100%')};
	display: flex;
	align-items: center;
		
	.subtask-assigned-input {
		font-size: 13px;
		padding:0 12px;
		&::placeholder {
			font-size: 13px;
			color: #a1a1a1;
			opacity: 1;
		}
	}
`;

export const InputWrapperStyled = Styled.div`
	width: 90px;
`;

export const IconWrapper = Styled.div`
	margin-left: 5px;
`;
