import React from 'react';
import { Modal } from '@forecast-it/design-system';
import { FormattedMessage } from 'react-intl';
const RemoveDeliverableFromPhaseModal = ({ closeModal, callback }) => {
    const onClickConfirm = () => {
        callback();
        closeModal();
    };
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: 'deliverable.remove_deliverable_from_phase' })),
        React.createElement(Modal.Content, null,
            React.createElement(FormattedMessage, { id: 'deliverable.remove_deliverable_from_phase.description' })),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: onClickConfirm, dataCy: 'modal-btn-confirm' },
                React.createElement(FormattedMessage, { id: "common.confirm" })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: "common.go_back" })))));
};
export default RemoveDeliverableFromPhaseModal;
