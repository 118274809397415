import React from 'react';
import { TextAuxiliary, TextMain, ViewColumn, ViewRow } from './layout/PDFLayout';
const BaselinePDFMeta = ({ client, creatorName, date, intl: { formatMessage } }) => {
    return (React.createElement(ViewRow, null,
        client ? (React.createElement(ViewColumn, { style: { marginRight: '48px' } },
            React.createElement(TextAuxiliary, null, formatMessage({ id: 'baseline.pdf.prepared_for' })),
            React.createElement(TextMain, null, client.name),
            client.street ? React.createElement(TextMain, null, client.street) : null,
            client.city && client.zip ? (React.createElement(TextMain, null,
                client.city,
                ", ",
                client.zip)) : null,
            client.vat ? (React.createElement(TextMain, null,
                formatMessage({ id: 'company_details.vat_label' }),
                ": ",
                client.vat)) : null)) : null,
        React.createElement(ViewColumn, { style: { marginRight: '48px' } },
            React.createElement(TextAuxiliary, null, formatMessage({ id: 'baseline.pdf.prepared_by' })),
            React.createElement(TextMain, null, creatorName)),
        React.createElement(ViewColumn, { style: { marginRight: '48px' } },
            React.createElement(TextAuxiliary, null, formatMessage({ id: 'common.date' })),
            React.createElement(TextMain, null, date))));
};
export default BaselinePDFMeta;
