import React, { useMemo } from 'react';
import { NestedCard, FlexRow, StyledIcon, Text, SubHeading, FlexItem } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
import { SelectDeliverableCard } from './SelectDeliverableCard';
import { BUDGET_TYPE } from '../../constants';
import { DeliverableCard } from './DeliverablesCard';
import { showModal, MODAL_TYPE } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
const DeliverableIcon = () => React.createElement(StyledIcon, { icon: "deliverable", size: 'l' });
export const PhaseCard = ({ phase, company, currency, eyeOptionMap, editMode, setProjectBaselineItems, phaseBaselineItems, groupedProjectTotals, project, setProjectPhaseDeliverables, projectPhaseDeliverables, }) => {
    var _a;
    const { formatMessage } = useIntl();
    const { startDay, startYear, startMonth, deadlineDay, deadlineMonth, deadlineYear, name } = phase;
    const projectBudget = (project === null || project === void 0 ? void 0 : project.baselineTarget) || 0;
    const deliverables = ((_a = project === null || project === void 0 ? void 0 : project.deliverables) === null || _a === void 0 ? void 0 : _a.edges) || [];
    const isFixedPrice = (project === null || project === void 0 ? void 0 : project.budgetType) === BUDGET_TYPE.FIXED_PRICE || (project === null || project === void 0 ? void 0 : project.budgetType) === BUDGET_TYPE.FIXED_PRICE_V2;
    const projectDeliverablesIdSet = useMemo(() => new Set(deliverables.map(deliverable => { var _a; return (_a = deliverable === null || deliverable === void 0 ? void 0 : deliverable.node) === null || _a === void 0 ? void 0 : _a.id; }).filter(Boolean)), [deliverables]);
    const deliverablesInPhase = useMemo(() => (projectPhaseDeliverables === null || projectPhaseDeliverables === void 0 ? void 0 : projectPhaseDeliverables.filter(phaseDeliverable => { var _a; return phaseDeliverable.phaseId === phase.id && projectDeliverablesIdSet.has((_a = phaseDeliverable.deliverable) === null || _a === void 0 ? void 0 : _a.id); })) || [], [projectPhaseDeliverables, projectDeliverablesIdSet]);
    const phaseBaselineItemsNoDeliverable = useMemo(() => (phaseBaselineItems === null || phaseBaselineItems === void 0 ? void 0 : phaseBaselineItems.filter(phaseBaselineItem => !phaseBaselineItem.deliverableId)) || [], [phaseBaselineItems]);
    const numberOfDeliverables = (deliverablesInPhase === null || deliverablesInPhase === void 0 ? void 0 : deliverablesInPhase.length) || 0;
    const showManagePhasesModal = () => {
        var _a;
        showModal({
            type: MODAL_TYPE.PHASE_MANAGEMENT_MODAL,
            initialPhases: (_a = project === null || project === void 0 ? void 0 : project.phases) === null || _a === void 0 ? void 0 : _a.edges,
            project,
        });
    };
    const contextMenuOptions = editMode
        ? []
        : [{ name: formatMessage({ id: 'deliverables.guide_section.phases.button' }), onClick: showManagePhasesModal }];
    return (React.createElement(NestedCard, { colorTheme: "dark", dataCy: 'phase-nested-card' },
        React.createElement(NestedCard.Header, { mainIcon: "phase", sectionName: formatMessage({ id: 'common.phase' }), name: name || '', contextMenuOptions: contextMenuOptions, showContextMenu: !editMode },
            React.createElement(FlexItem, null,
                React.createElement(FlexRow, { justifyContent: "flex-end" },
                    React.createElement(DeliverableIcon, null),
                    React.createElement(SubHeading, null, numberOfDeliverables))),
            React.createElement(FlexItem, null,
                React.createElement(FlexRow, { gap: "s" },
                    React.createElement(Text, null, formatMessage({ id: 'common.start' })),
                    React.createElement(SubHeading, null, `${startDay}/${startMonth}/${startYear}`))),
            React.createElement(FlexItem, null,
                React.createElement(FlexRow, { gap: "s" },
                    React.createElement(Text, null, formatMessage({ id: 'common.end' })),
                    React.createElement(SubHeading, null, `${deadlineDay}/${deadlineMonth}/${deadlineYear}`)))),
        numberOfDeliverables ? (React.createElement(NestedCard.CollapsibleContentWrapper, null, [
            ...deliverablesInPhase
                .map(phaseDeliverable => {
                const items = phaseBaselineItems === null || phaseBaselineItems === void 0 ? void 0 : phaseBaselineItems.filter(item => {
                    var _a, _b;
                    return ((_a = phaseDeliverable === null || phaseDeliverable === void 0 ? void 0 : phaseDeliverable.deliverable) === null || _a === void 0 ? void 0 : _a.id) &&
                        item.deliverableId.toString() === ((_b = phaseDeliverable === null || phaseDeliverable === void 0 ? void 0 : phaseDeliverable.deliverable) === null || _b === void 0 ? void 0 : _b.id);
                });
                return (React.createElement(NestedCard.Content, null,
                    React.createElement(DeliverableCard, { phaseDeliverable: phaseDeliverable, currency: currency, eyeOptionMap: eyeOptionMap, editMode: editMode, company: company, phase: phase, setProjectBaselineItems: setProjectBaselineItems, baselineItems: items, groupedProjectTotals: groupedProjectTotals, projectBudget: projectBudget, isFixedPrice: isFixedPrice, projectDeliverables: deliverables, setPhaseDeliverables: setProjectPhaseDeliverables })));
            })
                .filter(Boolean),
            ...(phaseBaselineItemsNoDeliverable.length > 0
                ? [
                    React.createElement(NestedCard.Content, null,
                        React.createElement(DeliverableCard, { currency: currency, eyeOptionMap: eyeOptionMap, editMode: editMode, company: company, phase: phase, setProjectBaselineItems: setProjectBaselineItems, baselineItems: phaseBaselineItemsNoDeliverable, groupedProjectTotals: groupedProjectTotals, projectBudget: projectBudget, isFixedPrice: isFixedPrice, projectDeliverables: deliverables, setPhaseDeliverables: setProjectPhaseDeliverables })),
                ]
                : []),
            ...(editMode
                ? [
                    React.createElement(NestedCard.Content, null,
                        React.createElement(SelectDeliverableCard, { deliverables: deliverables || [], phaseId: phase.id, setProjectPhaseDeliverables: setProjectPhaseDeliverables, deliverablesInPhase: deliverablesInPhase, project: project, text: formatMessage({ id: 'deliverable.select_another_deliverable' }) })),
                ]
                : []),
        ])) : (editMode && (React.createElement(NestedCard.UncollapsibleContentWrapper, null,
            React.createElement(NestedCard.Content, null,
                React.createElement(SelectDeliverableCard, { deliverables: deliverables || [], phaseId: phase.id, setProjectPhaseDeliverables: setProjectPhaseDeliverables, deliverablesInPhase: deliverablesInPhase, project: project, text: formatMessage({ id: 'deliverable.select_deliverable' }) })))))));
};
