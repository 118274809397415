import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { Table } from '@forecast-it/design-system';
export const TimeEstimatesTable = forwardRef((props, ref) => {
    var _a, _b;
    const { editMode, onDeleteRow, company, onRoleSelect, recalculate, tableData, setTableData, eyeOptionMap } = props;
    const { formatMessage } = useIntl();
    const getRoleName = (row) => {
        var _a, _b;
        const role = (_b = (_a = company === null || company === void 0 ? void 0 : company.roles) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.find(edge => { var _a; return ((_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.id) === row.roleId; });
        if (role && role.node && role.node.name) {
            return role.node.name;
        }
        return formatMessage({ id: 'deliverable.select_role' });
    };
    const currency = company.currency || '';
    return (React.createElement(Table, { width: '100%', tableRef: ref, setTableData: setTableData, data: tableData },
        editMode ? (React.createElement(Table.DropdownColumn, { header: formatMessage({ id: 'deliverable.columns.role' }), label: getRoleName, width: "20%" }, (_b = (((_a = company === null || company === void 0 ? void 0 : company.roles) === null || _a === void 0 ? void 0 : _a.edges) || [])) === null || _b === void 0 ? void 0 : _b.map(role => {
            var _a;
            return (React.createElement(Table.DropdownColumn.ListItem, { onClick: row => {
                    var _a, _b;
                    ((_a = role === null || role === void 0 ? void 0 : role.node) === null || _a === void 0 ? void 0 : _a.id) && onRoleSelect((_b = role === null || role === void 0 ? void 0 : role.node) === null || _b === void 0 ? void 0 : _b.id, row);
                } }, (_a = role === null || role === void 0 ? void 0 : role.node) === null || _a === void 0 ? void 0 : _a.name));
        }))) : (React.createElement(Table.TextColumn, { accessorKey: 'roleName', header: formatMessage({ id: 'deliverable.columns.role' }), ellipsis: true })),
        editMode ? (React.createElement(Table.NumberInputColumn, { accessorKey: 'hours', header: formatMessage({ id: 'deliverable.columns.hours' }), onInputChange: recalculate, width: "20%" })) : (React.createElement(Table.TimeColumn, { accessorKey: 'hours', header: formatMessage({ id: 'deliverable.columns.hours' }), align: Table.Align.right, timeUnit: "hours", visible: eyeOptionMap ? eyeOptionMap['time_estimates.hours'] : true })),
        React.createElement(Table.CurrencyColumn, { accessorKey: 'valueOfService', header: formatMessage({ id: 'deliverable.columns.value_of_service' }), align: Table.Align.right, currency: currency, width: "12%", visible: eyeOptionMap ? eyeOptionMap['time_estimates.value_of_service'] : true }),
        React.createElement(Table.CurrencyColumn, { accessorKey: 'revenue', header: formatMessage({ id: 'deliverable.columns.revenue' }), align: Table.Align.right, currency: currency, width: "12%", visible: eyeOptionMap ? eyeOptionMap['time_estimates.revenue'] : true }),
        React.createElement(Table.CurrencyColumn, { accessorKey: 'cost', header: formatMessage({ id: 'deliverable.columns.cost' }), align: Table.Align.right, currency: currency, width: "12%", visible: eyeOptionMap ? eyeOptionMap['time_estimates.cost'] : true }),
        React.createElement(Table.CurrencyColumn, { accessorKey: 'profit', header: formatMessage({ id: 'deliverable.columns.profit' }), align: Table.Align.right, currency: currency, width: "12%", visible: eyeOptionMap ? eyeOptionMap['time_estimates.profit'] : true }),
        React.createElement(Table.CurrencyColumn, { accessorKey: 'ratePerHour', header: formatMessage({ id: 'deliverable.columns.rate_per_hour' }), align: Table.Align.right, currency: currency, width: "12%", visible: eyeOptionMap ? eyeOptionMap['time_estimates.rate_per_hour'] : true }),
        React.createElement(Table.CurrencyColumn, { accessorKey: 'costPerHour', header: formatMessage({ id: 'deliverable.columns.cost_per_hour' }), align: Table.Align.right, currency: currency, width: "12%", visible: eyeOptionMap ? eyeOptionMap['time_estimates.cost_per_hour'] : true }),
        React.createElement(Table.ActionColumn, { header: 'contextmenu', hideHeader: true, hidden: !editMode },
            React.createElement(Table.ActionColumn.Option, { "data-cy": 'delete-baseline-role', onClick: onDeleteRow }, formatMessage({ id: 'deliverable.columns.delete_time_estimate' })))));
});
