import {Tooltip} from '@forecast-it/design-system';
import React from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import EmptyState from '../../forecast-app/shared/components/empty-states/empty_state';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {remapAndFormatFinancialMessage} from '../../forecast-app/shared/util/FinancialInternationalisationUtil';
import {idFromGlobalId} from '../../forecast-app/shared/util/GlobalIdUtil';
import Util from '../../forecast-app/shared/util/util';
import {ColumnTitleItem, PhasesTitle, PhasesWrapper} from './InitialPlan.styled';
import {getTooltipForHeader, initExpandedPhasedLocalStorage} from './InitialPlanUtil';
import Phase from './Phase';

export const PhasesSection = ({
	project,
	roles,
	disabledRoleIds,
	expenseCategories,
	phaseSortOrder,
	phases,
	currencySymbol,
	enabledColumns,
	theEyeOptions,
	toggleCollapseAll,
	groupedFinancialNumbers,
	isFixedPrice,
	useFixedPriceForBaselineRevenue,
}) => {
	const intl = useIntl();
	const projectStartDate = Util.CreateNonUtcMomentDate(
		project.projectStartYear,
		project.projectStartMonth,
		project.projectStartDay
	);
	const projectEndDate = Util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay);

	const sortedPhases = phases.sort((a, b) => phaseSortOrder.indexOf(a.node.id) - phaseSortOrder.indexOf(b.node.id));

	let expandMap = initExpandedPhasedLocalStorage(project.id, sortedPhases);
	const enabledColumnEntries = Object.entries(enabledColumns);

	let listWidth = 64 + 20 + 253 + 53 + 28; // Padding and extra columns
	enabledColumnEntries?.forEach(column => {
		if (theEyeOptions[column[1] - 1]) {
			const columnWidth = theEyeOptions[column[1] - 1].width;
			listWidth += columnWidth + 8;
		}
	});

	const getElemProps = value => {
		return theEyeOptions[value - 1];
	};

	const spanStyle = {
		fontSize: '9px',
		color: '#a1a1a1',
		textTransform: 'uppercase',
		fontWeight: '500',
		letterSpacing: '1px',
	};

	const getElemForHeaderColTitle = (column, value, index) => {
		if (!value) return null;
		const elemProps = getElemProps(value);

		const columnTitle = (
			<ColumnTitleItem
				key={`header-${column}-title`}
				align={elemProps.align}
				width={elemProps.width}
				paddingLeft={elemProps.leftOffset}
			>
				{getTooltipForHeader(column, remapAndFormatFinancialMessage, isFixedPrice, useFixedPriceForBaselineRevenue) ? (
					<Tooltip
						width={'xl'}
						content={getTooltipForHeader(
							column,
							remapAndFormatFinancialMessage,
							isFixedPrice,
							useFixedPriceForBaselineRevenue
						)}
						interactable={false}
					>
						<span style={spanStyle}>
							{remapAndFormatFinancialMessage({
								id: elemProps.translationIdPhaseHeader || elemProps.translationId,
							})}
						</span>
					</Tooltip>
				) : (
					remapAndFormatFinancialMessage({id: elemProps.translationIdPhaseHeader || elemProps.translationId})
				)}
			</ColumnTitleItem>
		);
		return columnTitle;
	};

	return (
		<CustomScrollDiv horizontalandverticalcontent>
			<PhasesWrapper data-cy={'baseline-phases'} width={listWidth}>
				<PhasesTitle>
					<ColumnTitleItem width={245} growable></ColumnTitleItem>
					{enabledColumnEntries.map(([col, val], index) => getElemForHeaderColTitle(col, val, index))}
					<ColumnTitleItem width={45}></ColumnTitleItem>
					<ColumnTitleItem width={20}></ColumnTitleItem>
				</PhasesTitle>
				{sortedPhases.length > 0 ? (
					sortedPhases.map(phase => {
						const phaseId = idFromGlobalId(phase.node.id);
						return (
							<Phase
								theEyeOptions={theEyeOptions}
								key={phase.node.id}
								intl={intl}
								phase={phase.node}
								projectId={project.id}
								project={project}
								projectStartDate={projectStartDate}
								projectEndDate={projectEndDate}
								projectColor={project.projectColor}
								roles={roles}
								disabledRoleIds={disabledRoleIds}
								expenseCategories={expenseCategories}
								currencySymbol={currencySymbol}
								enabledColumnEntries={enabledColumnEntries}
								isCollapsed={expandMap.get(phase.node.id)}
								toggleCollapseAll={toggleCollapseAll}
								phaseFinacialNumbers={groupedFinancialNumbers.get(phaseId)}
							/>
						);
					})
				) : (
					<EmptyState pageName={EmptyState.EMPTY_STATE.BASELINE}></EmptyState>
				)}
			</PhasesWrapper>
		</CustomScrollDiv>
	);
};

export default createFragmentContainer(PhasesSection, {
	phases: graphql`
		fragment PhasesSection_phases on PhaseTypeEdge @relay(plural: true) {
			node {
				id
				...Phase_phase
				startYear
				startMonth
				startDay
				deadlineYear
				deadlineMonth
				deadlineDay
			}
		}
	`,
});
