import React from 'react';
import BaselinePDFItem from './BaselinePDFItem';
import { TextMain, TextSubHeader, ViewLine, ViewSection } from './layout/PDFLayout';
const BaselinePDFPhase = ({ phase, intl: { formatMessage } }) => {
    return (React.createElement(ViewSection, null,
        React.createElement(TextSubHeader, null, phase.name),
        React.createElement(ViewLine, null,
            React.createElement(TextMain, { bold: true, style: { width: '40%' } }, formatMessage({ id: 'baseline.pdf.item' })),
            React.createElement(TextMain, { bold: true, style: { textAlign: 'right', width: '20%' } }, formatMessage({ id: 'common.hours' })),
            React.createElement(TextMain, { bold: true, style: { textAlign: 'right', width: '20%' } }, formatMessage({ id: 'project_budget.hourly_rate' })),
            React.createElement(TextMain, { bold: true, style: { textAlign: 'right', width: '20%' } }, formatMessage({ id: 'baseline.pdf.total_price' }))),
        phase.items.map(item => {
            return React.createElement(BaselinePDFItem, { key: item.id, item: item });
        }),
        React.createElement(ViewLine, { noBorder: true },
            React.createElement(TextMain, { style: { width: '60%' } }),
            React.createElement(TextMain, { bold: true, style: { textAlign: 'right', width: '20%' } }, formatMessage({ id: 'baseline.pdf.phase_total' })),
            React.createElement(TextMain, { bold: true, style: { textAlign: 'right', width: '20%' } }, phase.total))));
};
export default BaselinePDFPhase;
